import React, { useState, useContext, useRef } from "react";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
  PrimaryButton,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";
import ToggleSwitch from "../ui/ToggleSwitch";
import DatePicker from "react-datepicker";
import {
  faScrewdriver,
  faWrench,
  faPlay,
  faTrash,
  faExpand,
  faBoxOpen,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import PopupExpensesFiles from "./PopupExpensesFiles.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.darkGray};
  margin: 5px;
  & div {
    position: relative;
    max-width: 33%;
    cursor: pointer;
    & embed {
      max-height: 55px;
      max-width: 55px;
      margin: 5px;
    }
    & #myIframe {
      max-height: 55px;
      max-width: 55px;
      margin: 5px;
    }
    & .closeBtn {
      position: absolute;
      z-index: 10;
      opacity: 0;
      left: 40%;
      font-size: 1rem;
      line-height: 40px;
      text-align: center;
    }
    &:hover {
      & embed {
        opacity: 0.5;
      }
      & .closeBtn {
        opacity: 1;
      }
    }
  }
`;

const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;
const isPdf = (e) => {
  console.log(e);
  if (String(e).includes("pdf")) {
    console.log("sss");
    return true;
  } else {
    return false;
  }
};
const setEmbedSyle = () => {
  var someimage = document.getElementById("myEmbed");
  console.log(someimage);

  for (var p in someimage) {
    var value = null;
    try {
      value = someimage[p];
    } catch (err) {}

    if (value) console.log(p, value);
  }
};
export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};
const openPopUpAlert = (context, e, oldImages, eprops) => {
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <PopupExpensesFiles
          url={e}
          images={oldImages}
          eporps={eprops}
        ></PopupExpensesFiles>
      ),
    },
  });
};
function ExpenseForm(props) {
  console.log(props);
  const context = useContext(Context);
  let [imgs, setImages] = useState([]);
  let [oldImages, setOldImages] = useState(props.init ? props.init.media : []);
  let [isRule, setRule] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [action, setAction] = useState(props.action ? props.action : 0);
  console.log("action", action);
  var whichAction;
  console.log(props.init);
  const eprops = props;
  console.log(eprops);
  const onFileChanged = function (e) {
    e.preventDefault();
    let newArray = [...imgs];
    Array.from(e.target.files).forEach((file) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        newArray.push({ file: file, previewUrl: reader.result });
        //setImages(newArray);
        setImages((prevMovies) => [prevMovies, ...newArray]);
      };
      reader.readAsDataURL(file);
    });
    console.log(newArray);
  };
  const submitForm = async (values) => {
    let res, urls;
    try {
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });
      if (props.init == null) {
        urls = await FirebaseService.uploadImagesToStorage(imgs, "expenses");
        console.log(urls);
        res = await FirebaseService.createExpense(
          values,
          urls,
          props.id,
          isRule
        );
      } else {
        let newUploadUrls = await FirebaseService.uploadImagesToStorage(
          imgs,
          "expenses"
        );
        urls = [...oldImages, ...newUploadUrls];
        res = await FirebaseService.updateExpense(values, urls);
      }

      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      console.log(res);
      props.refresh(Math.random());
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  const deleteExpense = async (id) => {
    try {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: true,
          modalComponent: "",
        },
      });
      let res = await FirebaseService.deleteExpense(id);

      console.log(res);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      props.refresh(Math.random());
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          !props.init
            ? {
                amount: 0,
                type: "",
                description: "",
                paidAt: startDate,
                source: "",
              }
            : props.init
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={false}
        validationSchema={yup.object().shape({
          amount: yup.number().required(),
          description: yup.string().required(),
          source: yup.string().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            {props.init === null ? (
              <Label>
                Generate Rule
                <ToggleSwitch
                  Name="rulegen"
                  dataYes="YES"
                  dataNo="NO"
                  defaultChecked={isRule}
                  onChangeHandler={() => setRule(!isRule)}
                ></ToggleSwitch>
              </Label>
            ) : (
              <></>
            )}
            <Label>
              Amount
              {errors.amount && <Text color="red">{errors.amount}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amount}
                border={errors.amount && "1px solid red"}
                type="number"
                name="amount"
                placeholder="Expense Amount"
              />
            </Label>
            <Label>
              Paid at
              <DatePickerField
                name="paidAt"
                value={values.paidAt}
                onChange={setFieldValue}
              />
            </Label>
            <Label>
              Source
              {errors.source && <Text color="red">{errors.source}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.source}
                border={errors.source && "1px solid red"}
                type="text"
                name="source"
                placeholder="Source"
              />
            </Label>

            <Label>
              Expense Type
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.type}
                menuPlacement="top"
                border={errors.type && "1px solid red"}
                type="text"
                name="type"
                placeholder="Expense Amount"
              >
                <option value="City tax">City Tax</option>
                <option value="State tax">State Tax</option>
                <option value="Cleaning fee">Cleaning fee</option>
                <option value="Management fee">Management fee</option>
                <option value="Processing fee">Processing Fee</option>
                <option value="Channel fee">Channel Fee</option>
                <option value="Pool Service">Pool Service</option>
                <option value="Repair & Maintenance">
                  Repair & Maintenance
                </option>
                <option value="Lawn & Yard Services">
                  Lawn & Yard Services
                </option>
                <option value="Extra Cleaning">Extra Cleaning</option>
                <option value="Software">Software</option>
                <option value="Tax Filing Fee">Tax Filing Fee</option>
                <option value="Electricity">Electricity</option>
                <option value="Internet">Internet</option>
                <option value="Cables">Cables</option>
                <option value="Water">Water</option>
                <option value="Gas">Gas</option>
                <option value="Listing Setup">Listing Setup</option>
                <option value="Mortgage Interest">Mortgage Interest</option>
                <option value="Supplies">Supplies</option>
                <option value="Field Receipts">Field Receipts</option>
                <option value="Linen Fee">Linen Fee</option>
                <option value="Other">Other</option>
                <option value=""></option>
              </Select>
            </Label>

            <Label>
              Description
              {errors.description && (
                <Text color="red">{errors.description}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                border={errors.description && "1px solid red"}
                type="text"
                name="description"
                placeholder="Description"
              />
            </Label>

            <Label>
              Upload Media
              <Input type="file" onChange={onFileChanged} multiple="multiple" />
            </Label>
            <Images>
              {oldImages.map((e) => (
                <div
                  onClick={() => {
                    console.log(e);
                    let buttonValue = openPopUpAlert(
                      context,
                      e,
                      oldImages,
                      eprops
                    );
                  }}
                >
                  {isPdf(e) ? (
                    <>
                      <embed src={e}></embed>
                    </>
                  ) : (
                    <>
                      <iframe
                        id="myIframe"
                        srcDoc={`<html><body><img style=width:100% src=${e}></body></html>`}
                      ></iframe>
                    </>
                  )}
                  <span className="closeBtn">
                    <FontAwesomeIcon icon={faWrench}></FontAwesomeIcon>
                  </span>
                </div>
              ))}
            </Images>
            <ButtonsContainer>
              <PrimaryButton type="submit">Save</PrimaryButton>
              {props.init !== null ? (
                <DeleteButton
                  type="button"
                  onClick={async () => {
                    let a = window.confirm(
                      "Are you sure you want to delete the expense?"
                    );
                    if (a) {
                      try {
                        await deleteExpense(props.init.expenseId);
                        props.refresh();
                      } catch (e) {
                        console.log(e);
                        context.actions({
                          type: "setState",
                          payload: {
                            ...context.state,
                            isError: true,
                            errorMsg: e.message,
                          },
                        });
                      }
                    }
                  }}
                >
                  DELETE
                </DeleteButton>
              ) : (
                <></>
              )}
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default ExpenseForm;
